import { useTranslation } from "next-i18next";
import Image from "next/image";
import { FC } from "react";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { customerFeedbacks } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./CustomerFeedback.module.scss";

const CustomerFeedback: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.customer_feedback}>
      <Container className={styles.container}>
        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.see_what_clients_say_about_us)}
        </h2>

        <div className={styles.feedbacks} data-aos="fade-up">
          {customerFeedbacks?.map((feedback, index) => (
            <div key={index} className={styles.feedback}>
              <div className="flex items-center gap-3">
                <Image
                  className={styles.avatar}
                  src={feedback.avatar}
                  alt={feedback.name}
                  width={48}
                  height={48}
                />

                <div className="flex flex-col flex-1 gap-1">
                  <Text type="title-3" color={Colors.GREY_800}>
                    {feedback.name}
                  </Text>

                  <div className="flex items-center gap-1">
                    <Icon name="verify" size={20} color={Colors.ACCENT_GREEN} />
                    <Text type="body-2" color={Colors.GREY_500}>
                      Verified purchaser
                    </Text>
                  </div>
                </div>

                <div className="flex items-center gap-1">
                  <Icon name="star-fill" color={Colors.ACCENT_YELLOW} />
                  <Text type="title-3" color={Colors.GREY_800}>
                    {feedback.star}
                  </Text>
                </div>
              </div>

              <div className={styles.divider} />

              <Text
                type="title-2"
                color={Colors.GREY_600}
                className="!leading-normal"
              >
                {feedback.title}
              </Text>

              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: feedback.description }}
              />
            </div>
          ))}
        </div>

        <div className={styles.blur}></div>
      </Container>
    </div>
  );
};

export default CustomerFeedback;
