import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import Modal from "react-modal";
import Colors from "~/constants/colors";
import Trans from "~/types/translationKeys";
import Text from "../Text";
import ContactUsContext, { FormValues } from "./ContactUs.context";
import Complete from "./components/Complete";
import ContactForm from "./components/ContactForm";

interface ContactUsModalProps {
  open: boolean;
  handleClose: () => void;
}

const ContactUsModal: FC<ContactUsModalProps> = ({ open, handleClose }) => {
  const [isComplete, setIsComplete] = useState(false);
  const [values, setValues] = useState<FormValues>();
  const { t } = useTranslation();

  const close = () => {
    setValues({ email: "", numberOfAgents: "" });
    setIsComplete(false);
    handleClose();
  };

  return (
    <ContactUsContext.Provider
      value={{
        handleClose: close,
        isComplete,
        setIsComplete,
        values,
        setValues,
      }}
    >
      <Modal
        closeTimeoutMS={250}
        isOpen={open}
        onRequestClose={close}
        style={{
          overlay: { zIndex: 10000, backgroundColor: "rgba(23, 24, 26, 0.50)" },
          content: {
            border: "none",
            width: 464,
            height: "fit-content",
            borderRadius: 8,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <Text className="pb-4" type="title-2" color={Colors.GREY_800}>
          {t(Trans.contact_us)}
        </Text>
        {isComplete ? <Complete /> : <ContactForm />}
      </Modal>
    </ContactUsContext.Provider>
  );
};

export default ContactUsModal;
