import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";
import Icon from "~/components/Icon";
import Trans from "~/types/translationKeys";
import styles from "./Integrations.module.scss";

const Integrations: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section id="integrations" className={styles.integrations}>
      <div className={styles.wrapper}>
        <div className={styles.line} data-aos="fade-up" />

        <div className={styles.line_2} data-aos="fade-up" />

        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.technical_integrations_made_easy)}
        </h2>

        <p className={styles.description} data-aos="fade-up">
          {t(Trans.deeply_integrate_ai_into_your_applications_with)}
        </p>

        <div className={styles.btns} data-aos="fade-up">
          <div className={classNames(styles.button, styles.sdk)}>
            {t(Trans.website_sdk)}
          </div>
          <div className={classNames(styles.button, styles.react_native)}>
            {t(Trans.react_native)}
          </div>
          <div className={classNames(styles.button, styles.flutter)}>
            {t(Trans.flutter)}
          </div>
        </div>

        <div
          className={classNames(styles.button, styles.add_ons)}
          data-aos="fade-up"
        >
          <Icon name="box" className={styles.icon} />
          {t(Trans.ai_add_ons)}
        </div>
        <div
          className={classNames(styles.button, styles.customer_authentication)}
          data-aos="fade-up"
        >
          <Icon name="profile-tick" className={styles.icon} />
          {t(Trans.customer_authentication)}
        </div>

        <Image
          src="/svgs/integration.svg"
          alt="integrations"
          width={1030}
          height={295}
          className={styles.image}
          data-aos="fade-up"
        />
      </div>
    </section>
  );
};

export default Integrations;
