import { useTranslation } from "next-i18next";
import { FC, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { Container } from "~/components/Container";
import Trans from "~/types/translationKeys";
import styles from "./DemoVideo.module.scss";

const DemoVideo: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref, inView } = useInView({ threshold: 0.7 });
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [inView]);

  return (
    <section id="how-it-work" ref={ref} className={styles.demo_video}>
      <Container>
        <h2
          className="!mb-3 section-title"
          data-aos="fade-up"
          dangerouslySetInnerHTML={{ __html: t(Trans.see_how_cx_genie_works) }}
        ></h2>
        <p className={styles.description} data-aos="fade-up">
          {t(
            Trans.create_intelligent_chatbots_that_automate_processes_personalize_interactions_and_unlock_the_power_of_ai
          )}
        </p>
        <div className={styles.video_container}>
          <video
            data-aos="fade-up"
            data-aos-delay="250"
            ref={videoRef}
            className={styles.video}
            width="100%"
            controls
            muted
            loop
            playsInline
            preload="auto"
          >
            <source
              src="https://storage.cxgenie.ai/landing%2Fdemo.mp4"
              type="video/mp4"
            />
            {t(Trans.your_browser_does_not_support_the_video_tag)}
          </video>
        </div>
      </Container>
    </section>
  );
};
export default DemoVideo;
