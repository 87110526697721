import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Container } from "~/components/Container";
import Text from "~/components/Text";
import { audiences } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./AudienceTarget.module.scss";

const AudienceItem = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.audience_item}>
      {t(name)}
      <div className={styles.audience_popover}>
        <p className={styles.name}>{t(name)}</p>
        <p className={styles.description}>{t(description)}</p>
      </div>
    </div>
  );
};

const AudienceTarget: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.audience_target}>
      <Container>
        <h1 className="section-title" data-aos="fade-up">
          {t(Trans.who_is_cx_genie_for)}
        </h1>
        <Text data-aos="fade-up">
          {t(
            Trans.cx_genie_is_on_a_mission_to_make_ai_accessible_for_everyone_with_just_a_few_clicks
          )}
        </Text>
        <div
          className="flex flex-wrap gap-3 mt-10 max-w-[945px] z-[2] relative"
          data-aos="fade-up"
        >
          {audiences.map((a) => (
            <AudienceItem key={a.name} {...a} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default AudienceTarget;
