import { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./Pill.module.scss";

interface PillProps {
  children?: ReactNode;
  className?: string;
  active?: boolean;
  onClick?: () => void;
}

const Pill: FC<PillProps> = (props) => {
  const { children, active, className, onClick } = props;
  return (
    <div
      className={classNames(styles.pill, className, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Pill;
