import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { FC, Fragment, useState } from "react";
import Toggle from "react-toggle";
import { Tooltip } from "react-tooltip";
import Button from "~/components/Button";
import ContactUsModal from "~/components/ContactUsModal";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import Trans from "~/types/translationKeys";
import styles from "./Pricing.module.scss";
import AppSumo from "./AppSumoDeal";

enum PackageType {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  PRO = "PRO",
  ENTERPRISE = "ENTERPRISE",
}

export interface PackagePrice {
  created_at: string;
  currency: "$";
  deleted_at: null;
  id: string;
  package_id: string;
  plan: PricingPlan;
  price: number;
  stripe_price_id: string;
  updated_at: string;
}

export interface Package {
  id: string;
  name: string;
  currency: string;
  price: number;
  chatbot_number?: number;
  message_number?: number;
  type: PackageType;
  chatbot_data_limit: number;
  max_monthly_messages?: number;
  max_member?: number;
  max_bot?: number;
  max_bot_data?: number;
  prices: PackagePrice[];
  order: number;
}

const ICON_MAP = {
  [PackageType.FREE]: "/svgs/basic.svg",
  [PackageType.PREMIUM]: "/svgs/premium.svg",
  [PackageType.PRO]: "/svgs/pro.svg",
  [PackageType.ENTERPRISE]: "/svgs/enterprise.svg",
};

const LABEL_MAP = {
  [PackageType.FREE]: Trans.basic,
  [PackageType.PREMIUM]: Trans.premium,
  [PackageType.PRO]: Trans.pro,
  [PackageType.ENTERPRISE]: Trans.enterprise,
};

const NOTES = {
  [PackageType.FREE]: "",
  [PackageType.PREMIUM]:
    Trans.for_businesses_with_30_customer_support_requests_day,
  [PackageType.PRO]:
    Trans.for_businesses_with_200_customer_support_requests_day_with_advanced_features_api_access,
  [PackageType.ENTERPRISE]:
    Trans.for_business_of_10_customers_support_with_ai_training_and_24_7_live_support,
};

const enterpriseBonus = [
  Trans.custom_ai_training_model,
  Trans.on_premise_solution,
  Trans.saml_sso,
  Trans.audit_logs,
  Trans["24_7_ticket_support"],
];

enum PricingPlan {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

interface PricingProps {
  packages: Package[];
}

const Pricing: FC<PricingProps> = ({ packages = [] }) => {
  const router = useRouter();
  const { utm_source, utm_campaign } = router.query;
  const [openModal, setOpenModal] = useState(false);
  const [pricingPlan, setPricingPlan] = useState<PricingPlan>(
    PricingPlan.MONTHLY
  );
  const { t } = useTranslation();

  if (!packages || packages.length === 0) {
    return null;
  }

  return (
    <section id="pricing" className={styles.pricing}>
      <Container>
        <div className="mb-10">
          <h2
            className={classNames("section-title !text-left !mb-5")}
            data-aos="fade-up"
          >
            {t(Trans.plans_that_fit_your_need)}
          </h2>
          <div className="flex flex-wrap items-center justify-between gap-5">
            <div className={styles.sub_title} data-aos="fade-up">
              {t(Trans.start_with_free_tier_no_credit_card)}
            </div>
            <div
              className="flex items-center gap-3 text-lg font-semibold md:gap-4"
              data-aos="fade-up"
            >
              {t(Trans.monthly)}
              <Toggle
                className={styles.switch}
                aria-label="price-type"
                icons={false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPricingPlan(PricingPlan.YEARLY);
                  } else {
                    setPricingPlan(PricingPlan.MONTHLY);
                  }
                }}
              />
              <p>
                {t(Trans.annually)}{" "}
                <span className="text-base text-normal-blue">
                  ({t(Trans.save_up_to_16)})
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.pricing_wrapper} data-aos="fade-up">
          {packages.map((pkg, index) => (
            <div
              key={pkg.id}
              className={classNames(
                styles.package_item_container,
                styles[pkg.type.toLowerCase()]
              )}
            >
              <div
                className={classNames(
                  styles.package_item,
                  styles[pkg.type.toLowerCase()]
                )}
              >
                {pkg.type === PackageType.PRO && (
                  <Fragment>
                    <div className={styles.most_choice}>
                      <Image
                        src="/svgs/sparks.svg"
                        alt="Spark icon"
                        width={20}
                        height={20}
                      />
                      <Text type="title-3" color="white">
                        {t(Trans.most_popular)}
                      </Text>
                    </div>
                  </Fragment>
                )}
                <div className={styles.header}>
                  <div className="flex items-center gap-3">
                    <Image
                      src={ICON_MAP[pkg.type] || "/svgs/pkg_free.svg"}
                      alt={t(LABEL_MAP[pkg.type])}
                      width={36}
                      height={36}
                    />
                    <h2 className={styles.name}>{t(LABEL_MAP[pkg.type])}</h2>
                  </div>

                  <div
                    className="text-sm h-[65px] text-[#36393F]"
                    dangerouslySetInnerHTML={{ __html: t(NOTES[pkg.type]) }}
                  />

                  <div className="flex items-center">
                    {pkg.type === PackageType.ENTERPRISE ? (
                      <span className={styles.lets_talk}>
                        {t(Trans.lets_talk)}
                      </span>
                    ) : (
                      <React.Fragment>
                        <div>
                          <span className={styles.price}>
                            {pkg.currency}
                            {pkg.type === PackageType.FREE
                              ? t(Trans.always_free)
                              : `$${(
                                  (pkg.prices.find(
                                    (p) => p.plan === pricingPlan
                                  )?.price || 0) /
                                  (pricingPlan === PricingPlan.MONTHLY ? 1 : 12)
                                ).toFixed(2)}`}
                          </span>
                          {pkg.type !== PackageType.FREE && (
                            <span className={styles.period}>
                              /{t(Trans.month)}
                            </span>
                          )}
                          {pricingPlan === PricingPlan.YEARLY && index > 0 && (
                            <span className="text-sm text-primary">
                              {" "}
                              ({t(Trans.save_16)})
                            </span>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className="p-5">
                  {pkg.type === PackageType.ENTERPRISE ? (
                    <Button
                      className="w-full"
                      type="outline"
                      onClick={() => setOpenModal(true)}
                    >
                      {t(Trans.contact_us)}
                    </Button>
                  ) : (
                    <Button
                      className="w-full"
                      type={pkg.type !== PackageType.PRO ? "outline" : "solid"}
                      onClick={() => {
                        router.push({
                          pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                          query: {
                            utm_source,
                            utm_campaign: utm_campaign || "template_onboarding",
                          },
                        });
                      }}
                    >
                      {t(Trans.get_started)}
                    </Button>
                  )}
                </div>

                <div className="flex flex-col items-start flex-1 gap-6 px-5 py-4 lg:items-stretch">
                  <div className="flex flex-col gap-3">
                    {pkg.type !== PackageType.FREE && (
                      <div className={styles.benefit}>
                        {t(Trans.everything_from)}{" "}
                        {t(
                          packages?.[index - 1]?.name?.toLowerCase()
                        )?.toLowerCase()}
                      </div>
                    )}
                    <div className={styles.benefit}>
                      <div>
                        <div>
                          {pkg?.type === PackageType.ENTERPRISE
                            ? "> 30,000"
                            : ""}
                          {pkg?.max_monthly_messages &&
                          pkg.max_monthly_messages < 0
                            ? ""
                            : pkg.max_monthly_messages?.toLocaleString()}{" "}
                          {t(Trans.messages_month)}.
                          <span
                            className="relative inline pl-7"
                            data-tooltip-id="message"
                          >
                            <Icon
                              className="top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute"
                              name="info-circle-2"
                              size={20}
                              color={Colors.GREY_300}
                            />
                          </span>
                          <Tooltip
                            className={styles.tooltip}
                            id="message"
                            content={t(
                              Trans.this_limit_only_applies_to_messages_replied_to_by_ai
                            )}
                            place="top"
                            style={{ width: 220 }}
                          />
                        </div>
                        <p>
                          <span className="font-semibold">
                            Quadruple the conversation limit
                          </span>{" "}
                          when using your own key
                        </p>
                      </div>
                    </div>
                    <div className={styles.benefit}>
                      <div>
                        {pkg?.type === PackageType.ENTERPRISE
                          ? "> 30"
                          : `${t(Trans.max)} `}
                        {pkg?.max_member && pkg?.max_member < 0
                          ? ""
                          : pkg.max_member}{" "}
                        {pkg?.max_member === 1
                          ? t(Trans.member)
                          : t(Trans.members)}
                        <Tooltip
                          className={styles.tooltip}
                          id="members"
                          content={t(
                            Trans.we_offer_a_concept_workspace_where_teams_can_create_and_add_customer_support_agents_to_work
                          )}
                          place="top"
                          style={{ width: 220 }}
                        />
                        <span
                          data-tooltip-id="members"
                          className="relative inline pl-7"
                        >
                          <Icon
                            className="top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute"
                            name="info-circle-2"
                            size={20}
                            color={Colors.GREY_300}
                          />
                        </span>
                      </div>
                    </div>
                    {(pkg.type === PackageType.FREE ||
                      pkg.type === PackageType.PREMIUM) && (
                      <div className={styles.benefit}>
                        {pkg.type === PackageType.FREE
                          ? t(Trans.max_1_chatbot)
                          : t(Trans.unlimited_chatbots)}
                      </div>
                    )}
                    {pkg.order < 5 && (
                      <div className={styles.benefit}>
                        {pkg.max_bot_data != -1
                          ? `${pkg?.max_bot_data} ${t(
                              Trans.documents_for_ai_knowledge_files_faqs_articles_urls
                            )}`
                          : t(Trans.unlimited_ai_knowledge_updates)}
                      </div>
                    )}
                    {pkg.type === PackageType.FREE ||
                    pkg.type === PackageType.PREMIUM ? (
                      <div className={styles.benefit}>
                        <div>
                          {t(Trans.install_chatbot_on)}{" "}
                          {pkg.type === PackageType.FREE
                            ? t(Trans.website)
                            : t(Trans.mobile_app)}
                          <Tooltip
                            className={styles.tooltip}
                            id="integration"
                            content={t(
                              Trans.if_you_do_not_have_a_website_or_mobile_app_simply_share_the_link_of_chatbot_with_customers_for_instant_ai_powered_customer_care
                            )}
                            place="top"
                            style={{ width: 220 }}
                          />
                          <span
                            data-tooltip-id="integration"
                            className="relative inline pl-7"
                          >
                            <Icon
                              className="top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute"
                              name="info-circle-2"
                              size={20}
                              color={Colors.GREY_300}
                            />
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {pkg.type === PackageType.FREE && (
                      <Fragment>
                        <div className={styles.benefit}>
                          <div>
                            {t(
                              Trans.hybrid_chat_mode_for_agent_with_ai_recommendation
                            )}
                            <Tooltip
                              className={styles.tooltip}
                              id="hybrid"
                              content={t(
                                Trans.all_customer_requests_can_be_handled_by_ai_or_human_agents_with_ai_support
                              )}
                              place="top"
                              style={{ width: 220 }}
                            />
                            <span
                              data-tooltip-id="hybrid"
                              className="relative inline pl-7"
                            >
                              <Icon
                                className="top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] absolute"
                                name="info-circle-2"
                                size={20}
                                color={Colors.GREY_300}
                              />
                            </span>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {pkg.type === PackageType.PRO && (
                      <Fragment>
                        <div className={styles.benefit}>
                          {t(Trans.usage_reports)}
                        </div>
                        <div className={styles.benefit}>
                          {t(Trans.api_access)}
                        </div>
                      </Fragment>
                    )}
                    {pkg.type === PackageType.PRO && (
                      <div className={styles.benefit}>
                        {t(Trans.live_chat_technical_support)}
                      </div>
                    )}
                    {pkg.type === PackageType.ENTERPRISE &&
                      enterpriseBonus.map((item, index) => (
                        <div
                          key={index}
                          className={classNames(styles.benefit, {
                            [styles.enterprise_special]: index === 0,
                          })}
                        >
                          {t(item)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <AppSumo /> */}
      </Container>

      <ContactUsModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </section>
  );
};

export default Pricing;
