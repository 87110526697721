import classNames from "classnames";
import { i18n, useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC, Fragment } from "react";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import { features } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./Features.module.scss";

interface Feature {
  icon?: string;
  name: string;
  link?: string;
  href?: string;
  width?: number;
}

const FeatureItem = ({ feature }: { feature: Feature[] }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.feature_item}>
      <div className={styles.right}>
        {feature.map((item, index) => (
          <div className={styles.item} key={item.name}>
            {item.icon && (
              <Image
                src={item.icon}
                alt={item.name}
                width={item.width}
                height={20}
              />
            )}
            <p className={styles.name}>
              {t(item.name)}
              {index !== feature.length - 1 && ", "}
            </p>
            {item.href && (
              <Link target="_blank" href={item.href} className={styles.link}>
                {item.link}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Features: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="features" className={styles.features}>
      <Container>
        <h2 className="!text-left section-title !mb-0" data-aos="fade-up">
          {t(Trans.cx_genie_is_your_all_in_one_chatbot_solution)}
        </h2>

        <p className={styles.description} data-aos="fade-up">
          {t(
            Trans.every_interaction_matters_manage_analyze_and_personalize_your_chatbot_ecosystem_efficiently_with
          )}
        </p>

        <div
          className="flex flex-col gap-[49px] lg:gap-[6rem]"
          data-aos="fade-up"
        >
          {features.map((item, index) => (
            <div
              key={item.title}
              className={classNames(styles.card, styles[`card_${index + 1}`])}
            >
              <div
                className="flex flex-col flex-1 gap-6"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 100}
              >
                <Image
                  src={item.icon}
                  alt={item.title}
                  width={56}
                  height={56}
                />
                <div className="flex flex-col gap-5">
                  <h3 className={styles.card_title}>{t(item.title)}</h3>
                  <p className={styles.card_description}>
                    {t(item.description)}
                  </p>
                  <div className="flex flex-col gap-4">
                    {item.features.map((feature, index) => (
                      <Fragment key={index}>
                        <FeatureItem feature={feature} />
                        {index < item.features.length - 1 && (
                          <div className={styles.divider} />
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
                {item.include_demo && (
                  <div
                    className={classNames(
                      styles.see_demo,
                      "flex items-center gap-2 py-2 cursor-pointer"
                    )}
                    onClick={() => {
                      if (i18n?.language === "vi") {
                        window.open(
                          "https://www.youtube.com/playlist?list=PLDjYzJq8XiPVflICtdrLK5qVngfO7Xy7g",
                          "_blank",
                          "noopener,noreferrer"
                        );
                      } else {
                        (window as any)?.demo_conversion?.();
                        window.open(
                          process.env.NEXT_PUBLIC_DEMO_URL,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }
                    }}
                  >
                    <Icon name="play-circle" />{" "}
                    <span>{t(Trans.see_demo_2)}</span>
                  </div>
                )}
              </div>
              <div
                className="relative w-full aspect-[590/438] max-w-[590px]"
                data-aos={index % 2 !== 0 ? "fade-right" : "fade-left"}
                data-aos-delay={(index + 1) * 50}
              >
                <Image src={item.thumb} alt={item.title} fill />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Features;
