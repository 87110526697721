import React from "react";

export interface FormValues {
  email: string;
  numberOfAgents: string;
}

interface ContactUsValues {
  handleClose?: () => void;
  isComplete?: boolean;
  setIsComplete?: (isComplete: boolean) => void;
  values?: FormValues;
  setValues?: (values: FormValues) => void;
}

const ContactUsContext = React.createContext<ContactUsValues>({});

export default ContactUsContext;
