import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { useCookies } from "react-cookie";
import Balancer from "react-wrap-balancer";
import Button from "~/components/Button";
import { Container } from "~/components/Container";
import DownloadAppButton from "~/components/DownloadAppButton";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";
import styles from "./Experience.module.scss";

const Experience: FC = () => {
  const router = useRouter();
  const { utm_source, utm_campaign } = router.query;
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);
  const isBlog = router.asPath.includes("resources/b");
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.experience)}>
      <Container>
        <div className={styles.container}>
          <div className="lg:w-[70%] mx-auto lg:mb-[4rem]" data-aos="fade-up">
            <h2 className={styles.title}>
              <Balancer>
                {t(Trans.welcome_to_cx_genie)}
                <br />
                {t(Trans.where_falling_behind_on_ai_is_not_an_option)}
              </Balancer>
            </h2>
            <p className={styles.description}>
              {t(
                Trans.experience_personalized_ai_chat_support_that_s_as_effortless_as_flipping_a_switch_simple_in_both_pricing_and_setup_join_us_and_stay_ahead_with_ai_powered_solutions
              )}
            </p>
            <div className="flex flex-col gap-4 my-8">
              <Link
                href={{
                  pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                  query: {
                    utm_source: isBlog
                      ? `${cookie["utm_source"] || "blog"}`
                      : `${cookie["utm_source"] || utm_source || ""}`,
                    utm_campaign: utm_campaign || "template_onboarding",
                    utm_blog: isBlog ? cookie["utm_blog"] : "",
                    others: cookie["others"] || getQueryParams(router.asPath),
                  },
                }}
              >
                <Button size="large">{t(Trans.build_your_own_chatbot)}</Button>
              </Link>
              <p className={styles.no_credit}>
                {t(Trans.no_credit_card_required)}
              </p>
            </div>
            <DownloadAppButton />
          </div>
          <Image
            src="/images/experience_mobile.png"
            alt="experience-mobile"
            width={461}
            height={423}
            className="object-contain object-bottom"
          />
        </div>
      </Container>
    </section>
  );
};

export default Experience;
