import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Container } from "~/components/Container";
import DownloadAppButton from "~/components/DownloadAppButton";
import CustomLottie from "~/components/Lottie";
import SaasButton from "~/components/SaasButton";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import Trans from "~/types/translationKeys";
import ECommerce from "../ECommerce";
import styles from "./Hero.module.scss";

const Hero: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className={styles.hero}>
      <Container>
        <div className="flex flex-col gap-12 lg:flex-row lg:gap-14">
          <div className={styles.left}>
            <div className="flex flex-col gap-3">
              <h1 className={styles.title} data-aos="fade-up">
                <span className={styles.circle_text}>
                  {t(Trans.automate_tasks_personalize_experiences)}
                  <br />
                  <span className="text-[#364DE7]">
                    {t(Trans.transform_business_with_ai)}
                  </span>
                </span>
              </h1>
              <p
                className={styles.description}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {t(
                  Trans.cx_genie_is_your_ai_powered_custom_engine_that_deliveres
                )}{" "}
                <br className="hidden lg:block" />
                <span
                  className={classNames(
                    styles.typing_effect,
                    styles[i18n.language]
                  )}
                />
              </p>
            </div>
            {/* <div className="flex items-center w-full gap-3 lg:gap-6 md:mt-0">
              <Link
                href={{
                  pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                  query: {
                    utm_source,
                    utm_campaign,
                  },
                }}
                data-aos="fade-up"
                data-aos-delay="200"
                target="_blank"
              >
                <Button className="px-6" size="large">
                  Let&apos;s build - It&apos;s free
                </Button>
              </Link>
              <Button
                className="px-6"
                size="large"
                data-aos="fade-up"
                data-aos-delay="300"
                type="outline"
                variant="secondary"
                onClick={() => {
                  (window as any)?.gtag_report_conversion_without_reload?.();
                  window.open(
                    process.env.NEXT_PUBLIC_DEMO_URL,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                See demo
              </Button>
            </div> */}
            <ECommerce />
            <div className="grid gap-4 md:grid-cols-2">
              <DownloadAppButton />
              <div className="flex flex-col gap-4">
                <Text
                  type="title-3"
                  color={Colors.GREY_400}
                  className="leading-normal"
                >
                  Featured on
                </Text>
                <SaasButton mode="dark" />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className="relative w-fit">
              <div className={styles.hero_img_container}>
                <video
                  className="object-contain"
                  autoPlay
                  loop
                  playsInline
                  width="100%"
                  muted
                >
                  <source
                    src="https://storage.cxgenie.ai/landing%2Fhero.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div
                className={classNames(styles.animated_logo, "hidden md:block")}
              >
                <CustomLottie src="/anims/animated_logo.json" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
