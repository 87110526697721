import axios from "axios";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { FC, useContext } from "react";
import Button from "~/components/Button";
import Input from "~/components/Input";
import Pill from "~/components/Pill";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { customerSupportAgentsOptions } from "~/constants/data";
import { isValidEmail } from "~/helpers/form.helpers";
import Trans from "~/types/translationKeys";
import ContactUsContext from "../ContactUs.context";
import styles from "../ContactUsModal.module.scss";

interface ContactFormProps {}

const ContactForm: FC<ContactFormProps> = () => {
  const { handleClose, setIsComplete, setValues } =
    useContext(ContactUsContext);
  const { t } = useTranslation();

  const {
    dirty,
    touched,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    submitForm,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      numberOfAgents: customerSupportAgentsOptions[0].value,
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      if (!values.email.trim()) {
        errors.email = t(Trans.please_enter_your, {
          name: t(Trans.email).toLowerCase(),
        });
      } else if (!isValidEmail(values.email)) {
        errors.email = t(Trans.please_enter_a_valid_email);
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setValues?.(values);
      const formData = new FormData();
      formData.append("Email", values.email);
      formData.append("Number Of Agents", values.numberOfAgents);
      formData.append("Sheet", "Enterprise");
      const res = await axios.post(
        `${process.env.NEXT_PUBLIC_SCRIPT_URL}`,
        formData
      );
      if (res.data.row) {
        setIsComplete?.(true);
        resetForm();
      }
    },
  });
  return (
    <>
      <div className="flex flex-col gap-4 my-4">
        <Text type="title-2" color={Colors.GREY_800} className="mb-2">
          {t(Trans.please_give_us_your_information_to_support_you_better)}
        </Text>

        <Input
          label={`${t(Trans.your, {
            name: t(Trans.email).toLowerCase(),
          })} *`}
          labelClassName={styles.input_label}
          placeholder={t(Trans.email_address)}
          {...getFieldProps("email")}
          error={touched.email && errors.email}
        />
        <div className="flex flex-col gap-2">
          <label className={styles.input_label}>
            {t(Trans.your_number_of_customer_support_agents)} *
          </label>
          <div className="flex flex-wrap gap-1 gap-y-2">
            {customerSupportAgentsOptions.map((item) => (
              <Pill
                key={item.label}
                active={values.numberOfAgents === item.value}
                onClick={() => setFieldValue("numberOfAgents", item.value)}
              >
                {item.label} {t(Trans.agents)}
              </Pill>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-5 mt-8">
        <Button variant="secondary" type="borderless" onClick={handleClose}>
          {t(Trans.cancel)}
        </Button>
        <Button disabled={!dirty} loading={isSubmitting} onClick={submitForm}>
          {t(Trans.submit)}
        </Button>
      </div>
    </>
  );
};

export default ContactForm;
