import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import Button from "~/components/Button";
import { Container } from "~/components/Container";
import Icon from "~/components/Icon";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { discordBenefits } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./Discord.module.scss";

const Discord: FC = () => {
  const { t } = useTranslation();

  return (
    <section id="discord" className={styles.discord}>
      <Container className={styles.discord_container}>
        <div className={styles.left} data-aos="fade-up">
          <Text
            type="headline-4"
            color={Colors.GREY_00}
            className="!leading-normal"
          >
            {t(Trans.get_more_from_cx_genie_join_our_discord)}
          </Text>

          <Text
            type="body-2"
            color={Colors.GREY_50}
            className="!leading-normal"
          >
            {t(
              Trans.unlock_the_full_potential_of_cx_genie_by_connecting_with_an_active_community_of_ai_enthusiasts_and_early_adopters_on_our_discord_server
            )}
          </Text>

          <Link
            href="https://discord.gg/B3heXRJ5dc"
            target="_blank"
            aria-label="Discord"
          >
            <Button variant="secondary">Join the conversation</Button>
          </Link>

          <Text
            type="body-2"
            color={Colors.GREY_50}
            className="!leading-normal"
          >
            {t(Trans.heres_what_awaits_you)}
          </Text>

          <div className={styles.benefits}>
            {discordBenefits.map((benefit, index) => (
              <div className={styles.benefit} key={index}>
                <Icon
                  name="check-circle"
                  className={styles.icon}
                  color={Colors.ACCENT_LIGHT_BLUE}
                />
                <div className="flex flex-col gap-2">
                  <Text
                    type="title-3"
                    color={Colors.GREY_00}
                    className="!leading-normal"
                  >
                    {t(benefit.name)}
                  </Text>
                  <Text color={Colors.GREY_50} className="!leading-normal">
                    {t(benefit.content)}
                  </Text>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <Image
            src="/images/discord.png"
            alt="Discord"
            width={440}
            height={440}
            className="object-contain"
          />
        </div>
      </Container>
    </section>
  );
};

export default Discord;
