import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Container } from "~/components/Container";
import { brands } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./Brand.module.scss";

const Brand = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.brand} id="brand">
      <Container className={styles.wrapper}>
        <h2 className={styles.title} data-aos="fade-up">
          {t(Trans.trusted_by_10000_customers_globally)}
        </h2>

        <div className={styles.list} data-aos="fade-up">
          {brands.map((brand, index) => (
            <Image
              src={brand.icon}
              key={index}
              alt={brand.name}
              width={brand.width}
              height={36}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Brand;
