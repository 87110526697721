import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import Button from "~/components/Button";
import Input from "~/components/Input";
import CustomLottie from "~/components/Lottie";
import { openUrl } from "~/helpers/data.helpers";
import Api from "~/services/api";
import Trans from "~/types/translationKeys";
import styles from "./ECommerce.module.scss";

const ECommerce: FC = () => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      url: "",
    },
    validate: (values) => {
      const errors: Record<string, string> = {};
      const regex =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

      if (!values.url.trim()) {
        errors.url = t(Trans.please_your_website_url);
      } else if (!values.url.match(regex)) {
        errors.url = t(Trans.please_a_valid_url);
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await Api({
          url: "/v1/report-shopify-campings",
          method: "POST",
          data: {
            field_count: "total_enter_store_url",
          },
        });

        const res = await Api<{ data: any }>({
          url: "/v1/shopify-stores/bot",
          method: "POST",
          data: {
            store_url: values.url,
          },
        });
        if (res) {
          resetForm();
          setIsInProgress(true);
          const botId = res?.data?.bot?.id;
          setTimeout(() => {
            setIsInProgress(false);
            (window as any).create_bot_conversion(
              `https://bots.cxgenie.ai/?aid=${botId}`
            );
            openUrl(`https://bots.cxgenie.ai/?aid=${botId}`);
          }, 10000);
        }
      } catch (e: any) {
        setError(e.response?.data?.message?.[0]);
      }
    },
  });

  return (
    <div
      className={styles.e_commerce}
      data-aos="fade-right"
      data-aos-delay="600"
    >
      <div className={styles.content_container}>
        {isInProgress ? (
          <div
            className="relative flex items-center justify-center mx-auto"
            style={{ width: "90%", height: 200 }}
          >
            <div className="absolute" style={{ height: 300, maxWidth: "100%" }}>
              <CustomLottie src="/anims/e_commerce_loading.json" />
            </div>
          </div>
        ) : (
          <div className="w-full">
            <h1 className={styles.title}>
              {t(
                Trans.build_your_free_ai_chatbot_in_seconds_just_enter_your_website
              )}
            </h1>
            <span className={styles.subtitle}>
              {t(Trans.no_sign_up_needed)}
            </span>
            <div className="flex flex-col items-start w-full gap-3 lg:items-end lg:flex-row">
              <Input
                containerClassName="flex-1 w-full"
                placeholder={t(Trans.enter_your, {
                  name: t(Trans.website),
                })}
                {...form.getFieldProps("url")}
              />
              <Button
                className="min-w-[167px]"
                size="large"
                variant="secondary"
                onClick={form.submitForm}
                loading={form.isSubmitting}
              >
                {t(Trans.create_free_chatbot)}
              </Button>
            </div>
            {(form.errors.url || error) && (
              <span className="text-xs text-red-500">
                {form.errors.url || error}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ECommerce;
