import React, { FC, useRef, useState } from "react";

interface LottieProps {
  src: string;
}

const CustomLottie: FC<LottieProps> = ({ src }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<any>(null);

  React.useEffect(() => {
    import("lottie-web").then((Lottie) => setLottie(Lottie.default));
  }, []);

  React.useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        // path to your animation file, place it inside public folder
        path: src,
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div ref={ref} className="w-full h-full"></div>;
};

export default CustomLottie;
