import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

import { useTranslation } from "next-i18next";
import Trans from "~/types/translationKeys";
import styles from "./DownloadAppButton.module.scss";

const DownloadAppButton: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.download_app_button}>
      <p className={styles.title}>{t(Trans.now_available_on)}</p>

      <div className={styles.icons}>
        <Link
          href="https://apps.apple.com/vn/app/cx-genie/id6474287133"
          target="_blank"
        >
          <Image
            src="/svgs/apple_store_logo.svg"
            width={120}
            height={40}
            alt="Google Play"
          />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=ai.cxgenie.app"
          target="_blank"
        >
          <Image
            src="/svgs/google_play_logo.svg"
            width={135}
            height={40}
            alt="Google Play"
          />
        </Link>
      </div>
    </div>
  );
};

export default DownloadAppButton;
