import classNames from "classnames";
import { useTranslation } from "next-i18next";
import React, { FC, useContext } from "react";
import Text from "~/components/Text";
import { customerSupportAgentsOptions } from "~/constants/data";
import Trans from "~/types/translationKeys";
import ContactUsContext from "../ContactUs.context";
import styles from "../ContactUsModal.module.scss";

const Complete: FC = () => {
  const { values } = useContext(ContactUsContext);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6 my-4">
      {values?.numberOfAgents === customerSupportAgentsOptions[0].value ? (
        <React.Fragment>
          <h3 className={styles.title}>
            👍 {t(Trans.great_we_will_get_your_back)}
          </h3>
          <Text>
            {t(
              Trans.cx_genie_has_available_plans_to_fit_your_requirements_start_with_the
            )}{" "}
            <span className={classNames(styles.plan)}>
              {t(Trans.free_plan)}
            </span>
            , {t(Trans.or_you_can_complete_the_free_trial_30_days_with)}{" "}
            <span className={classNames(styles.plan, styles.plus)}>
              {t(Trans.plus)}
            </span>{" "}
            {t(Trans.and)}{" "}
            <span className={classNames(styles.plan, styles.special)}>
              {t(Trans.special_plan)}
            </span>
            ! {t(Trans.no_credit_card_required2)}
          </Text>
          <div className={styles.box}>
            <a
              className={styles.link}
              href={process.env.NEXT_PUBLIC_GUIDE_VIDEO_URL}
              target="_blank"
            >
              {t(
                Trans.this_video_could_help_you_understand_more_about_what_cx_genie
              )}
            </a>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3 className={styles.title}>
            🌟 {t(Trans.great_let_the_story_begin)}
          </h3>
          <Text>
            {t(
              Trans.great_the_cx_genie_team_is_ready_to_have_a_discussion_with_you_to_learn_more_about_your_wishes_please_schedule_a_meeting_with_us_at_this_link
            )}{" "}
            <a
              className={styles.link}
              href={process.env.NEXT_PUBLIC_CXGENIE_CALENDLY}
              target="_blank"
            >
              {process.env.NEXT_PUBLIC_CXGENIE_CALENDLY}
            </a>
          </Text>
        </React.Fragment>
      )}
    </div>
  );
};

export default Complete;
