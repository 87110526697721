import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";
import { Container } from "~/components/Container";
import CustomLottie from "~/components/Lottie";
import { keyFeatures } from "~/constants/data";
import Trans from "~/types/translationKeys";
import styles from "./KeyFeatures.module.scss";

const KeyFeatures: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="key-features" className={styles.key_features}>
      <Container>
        <div className="flex flex-col gap-y-1">
          <div className={styles.sub_title} data-aos="fade-up">
            {t(Trans.beyond_basic_bots)}
          </div>

          <h2 className={styles.title} data-aos="fade-up">
            {t(Trans.cx_genie_for_smarter_interactions)}
          </h2>
        </div>

        <div className={styles.logo}>
          <CustomLottie src="/anims/animated_logo.json" />

          <Image
            src="/images/key_feature_bg.png"
            alt="key-features"
            width={1030}
            height={1030}
            className={styles.background}
          />
        </div>

        <div className={styles.list} data-aos="fade-up">
          {keyFeatures.map((feature, index) => (
            <div
              key={feature.name}
              className={classNames(
                styles.feature_item,
                styles[`feature_${index + 1}`]
              )}
            >
              <div className={styles.blur} />
              <Image
                src={feature.icon}
                alt={t(feature.name)}
                width={48}
                height={48}
              />
              <p className={styles.name}>{t(feature.name)}</p>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: t(feature.description) }}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default KeyFeatures;
